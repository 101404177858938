<template>
  <div class="py-16 lg:py-20" :style="{ backgroundColor: bgColor }">
    <div class="container mx-auto">
      <div class="grid grid-cols-5">
        <div></div>
        <div class="text-center col-span-5 lg:col-span-1 py-3 lg:py-0" v-for="col in footerCols" :key="col">
          <h1 :class="'custom-font-weight-h text-2xl lg:text-4xl mb-1 ' + titleColor">{{ col.heading }}</h1>
          <ul v-for="link in col.links" :key="link">
            <li>
              <a :href="link.link" :class="'mb-7 text-lg lg:text-xl transition duration-200 ' + secondaryColor"> {{ link.name }}</a>
            </li>
          </ul>
        </div>
        <div></div>
      </div>
      <div class="mt-5 lg:mt-20 grid justify-items-center">
        <img :src="'/' + logoSrc" alt="Logo" class="h-16 lg:h-20">
        <p :class="'text-xl lg:text-2xl font-light ' + copyrightColor">{{ copyright }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  props: {
    bgColor: String,
    footerCols: Array,
    logoSrc: String,
    copyright: String,
    titleColor: String,
    secondaryColor: String,
    copyrightColor: String,
  }
}
</script>

<style scoped>

</style>