<template>
  <div id="header" class="pt-25 flex h-screen container mx-auto">
    <div class="grid grid-cols-2">
      <div class="flex flex-wrap content-center col-span-2 lg:col-span-1 mx-5 lg:mx-auto">
          <h1 class="custom-heading h-custom-color">Najlacnejší webhosting široko ďaleko!</h1>
          <p class="p-custom-color custom-paragraph mr-20">
            Experimentujete s HTML, CSS a robíte si prvú stránku alebo máte
            na starosti veľký projekt? Pre oba prípady máme lacné a hlavne spoľahlivé riešenie!
          </p>
          <a href="https://admin.hostnow.cz/register" class="btn btn-blue mr-5 mt-3">ZAREGISTROVAŤ SA</a>
          <a href="https://admin.hostnow.cz/login" class="btn btn-white mt-3">PRIHLÁSIŤ SA</a>
<!--          <div class="w-full flex flex-wrap justify-left items-center mt-12">-->
<!--            <img src="../../public/logos/php.svg.png" alt="PHP logo" class="logo-technology">-->
<!--            <img src="../../public/logos/Ubuntu.png" alt="Ubuntu logo" class="logo-technology">-->
<!--            <img src="../../public/logos/mysql.png" alt="MySQL logo" class="logo-technology">-->
<!--          </div>-->
        </div>

      <div id="index-photo-wrapper" class="hidden lg:flex lg:ml-10 text-right">
        <img src="../../public/index-photo.jpg" alt="Index photo" id="index-photo" class="inline-block">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",

}
</script>

<style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

  body {
    font-family: 'Ubuntu', sans-serif;
  }

  #index-photo {
    border-radius: 60px;
    height: 500px;
    width: auto;
    object-fit: cover;
  }

  #index-photo-wrapper {
    height: 89vh;
    align-items: center;
  }

  .logo-technology {
    @apply w-28 lg:w-44 mr-12 mt-5;
  }
</style>