<template>

    <nav class="navbar py-4 lg:py-5 fixed w-full" :class="{ 'scrolled-nav': (scrollNav || (!navbarCollapsed && mobile)) }">
      <div class="container mx-auto flex flex-col lg:flex-row lg:items-center px-7 lg:px-0">
      <div class="flex justify-items-center items-center">
        <router-link :to="{ name: 'home' }" class="lg:mr-5 flex justify-center" v-on:click="activeNavItem = ''">
          <img src="../../public/light.png" alt="Logo" class="w-64">
        </router-link>
        <div id="nav-button" class="ml-10 h-8 w-10 bg-blue-700 rounded flex justify-center flex-wrap content-center ml-auto lg:hidden"
        v-on:click="navbarCollapsed = !navbarCollapsed">
          <font-awesome-icon icon="bars" class="text-white"/>
        </div>
      </div>
        <transition name="navbar-animation">
          <div id="nav-content" class="flex flex-col lg:flex-row lg:items-center px-7 lg:px-0 w-full lg:flex" v-if="!navbarCollapsed">
            <ul class="nav-items text-center mt-2 lg:mt-0" v-for="item in navItems" :key="item">
              <li class="nav-item" v-on:click="activeNavItem = item.name" :class="{active: activeNavItem === item.name}">
                <a v-if="item.link[0] !== '/'" :href="item.link" :target="'_blank'">{{ item.name }}</a>
                <router-link v-else :to="{ path: item.link }">{{item.name}}</router-link>
              </li>
            </ul>
            <a href="https://admin.hostnow.cz/" class="btn btn-dark mx-auto mt-6 md:mt-0 md:mx-0 md:ml-auto">ADMINISTRÁCIA</a>
          </div>
        </transition>
      </div>
    </nav>

</template>

<script>
export default {
  name: "navbar-modern",
  props: {
    navItems: Array
  },
  data: () => ({
    activeNavItem: '',
    navbarCollapsed: true,
    scrollNav: false,
    mobile: false
  }),
  created() {
    this.checkScreenSize();
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll)
    window.addEventListener('resize', this.checkScreenSize)
  },
  methods: {
    updateScroll() {
      let scrollPosition = window.scrollY;
      if (scrollPosition > 50){
        this.scrollNav = true;
        return
      }
      this.scrollNav = false;
    },

    checkScreenSize() {
      const screenSize = screen.width;
      if (screenSize > 1024){
        this.navbarCollapsed = false;
        this.mobile = false;
      }
      if (screenSize <= 1024){
        this.navbarCollapsed = true;
        this.mobile = true;
      }
    }
  }
}
</script>
<style scoped>

  .btn-dark:hover {
    background-color: #166ef3;
    color: white !important;
    box-shadow: 0 4px 32px -3px rgba(0, 97, 237, 0.42);
  }

  .navbar {
    transition: .2s all;
  }

  .nav-item{
    @apply mx-5;
    @apply text-lg text-white font-normal;
  }

  .nav-item:hover, .nav-item.active{
    @apply text-gray-500;
    transition: .1s;
  }

  .scrolled-nav {
    backdrop-filter: blur(15px);
    box-shadow: 0 0 15px 10px rgba(0, 0, 0, 0.05);
    background-color: rgba(0, 13, 32, 0.9);
  }

  #nav-button {
    transition: .3s;
  }

  #nav-button:hover {
    cursor: pointer;
    background-color: #166ef3;
    box-shadow: 0 4px 32px -3px rgba(0, 97, 237, 0.42);
  }

  .navbar-animation-enter-active,
  .navbar-animation-leave-active {
    transition: all .5s ease-out;
  }

  .navbar-animation-enter, .navbar-animation-leave-to {
    opacity: 0;
  }

</style>
