<template>
  <div class="py-20 lg:pt-32">
    <div class="container mx-auto">
      <h1 class="h-custom-color custom-heading text-center"> {{ heading }} </h1>
      <p class="p-custom-color custom-paragraph text-center mx-5 lg:mx-48 xl:mx-96"> {{ text }} </p>
      <div class="text-center">
        <a href="https://admin.hostnow.cz/register" class="btn mt-3 ml-2 inline-block" :class="btnLeftColorClass"> {{ btnLeftText }} </a>
        <a href="https://admin.hostnow.cz/" class="btn mt-3 ml-2 inline-block" :class="btnRightColorClass"> {{ btnRightText }} </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionTextMiddleButtons",
  props: {
    heading: String,
    text: String,
    btnLeftText: String,
    btnLeftColorClass: String,
    btnRightText: String,
    btnRightColorClass: String
  }
}
</script>

<style scoped>

</style>