<template>
  <span class="ml-1 text-red-500">*</span>
</template>

<script>
export default {
  name: "required"
}
</script>

<style scoped>

</style>