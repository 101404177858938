<template>
  <div class="mt-20 lg:mt-40 py-16 lg:py-20" :class="bgColorClass">
    <div class="container mx-auto">
      <h1 class="h-custom-color custom-heading text-center lg:mt-6">{{ heading }}</h1>
      <p class="p-custom-color custom-paragraph text-center mx-5 lg:mx-48 xl:mx-96"> {{ paragraph1 }} </p>
      <div class="text-center">
         <div class="info-box custom-paragraph text-center" v-for="icon in icons" :key="icon">
               <div>
                 <font-awesome-icon :icon="icon.icon" class="text-blue-800 text-2xl lg:text-5xl mb-5 lg:mt-5"/>
               </div>
           <p> {{ icon.text }} </p>
         </div>
      </div>
      <p class="p-custom-color custom-paragraph text-center mx-5 lg:mx-96"> {{ paragraph2 }} </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionTextMiddle",
  props: {
    heading: String,
    paragraph1: String,
    icons: Array,
    paragraph2: String,
    bgColorClass: String
  }
}
</script>

<style scoped>
  .info-box{
    @apply inline-block align-middle;
    @apply p-6 mx-5;
    @apply bg-white;
    @apply h-40 w-36 lg:h-52 lg:w-48;
    border-radius: 5px;
    border: 1px solid #d7e4fc;
  }
</style>