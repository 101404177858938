function page(path) {
    return () => import(/* webpackChunkName: '' */ `../pages/${path}`).then(m => m.default || m)
}

export default [
    { path: '/podmienky-ochrany-sukromia', component: page('GDPR'), name: 'gdpr' },
    { path: '/vseobecne-obchodne-podmienky', component: page('TermsOfUse'), name: 'tos' },
    { path: '/na-stiahnutie', component: page('Downloads'), name: 'downloads' },
    { path: '/', component: page('Homepage'), name: 'home'},
    { path: '/domov', component: page('Homepage'), name: 'home'},
    { path: '/stan-sa-partnerom', component: page('PartnershipCampaign'), name: 'partner'},
]
