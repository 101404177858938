<template>
  <div id="app">
    <component :is="layout" v-if="layout"/>
  </div>
</template>

<script>
// Load layout components dynamically.
const requireContext = require.context('./layouts', false, /.*\.vue$/)

const layouts = requireContext.keys()
    .map(file =>
        [file.replace(/(^.\/)|(\.vue$)/g, ''), requireContext(file)]
    )
    .reduce((components, [name, component]) => {
      components[name] = component.default || component
      return components
    }, {})

export default {
  el: '#app',

  components: {
  },

  data: () => ({
    layout: null,
    defaultLayout: 'default'
  }),

  metaInfo() {
    const {appName} = window.config

    return {
      title: appName,
      titleTemplate: `%s · ${appName}`
    }
  },

  methods: {
    /**
     * Set the application layout.
     *
     * @param {String} layout
     */
    setLayout(layout) {
      if (!layout || !layouts[layout]) {
        layout = this.defaultLayout
      }

      this.layout = layouts[layout]
    }
  }
}
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap');

  body {
    font-family: 'Ubuntu', sans-serif;
  }

</style>
