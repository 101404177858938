<template>
  <div>
    <Navbar :nav-items="[
      {name: 'Domov', link: '/'},
      {name: 'Status', link: 'https://status.hostnow.cz/'},
      {name: 'Wiki', link: 'https://wiki.hostnow.cz/'},
      {name: 'Blog', link: 'https://blog.hostnow.cz/'},
      {name: 'Discord', link: 'https://discord.gg/kEEzRq5THg'},
      {name: 'Rozbehni svoj projekt u nás!', link: '/stan-sa-partnerom'},
    ]"/>
    <Child/>
    <Footer

        bg-color="#eff6ff"

        title-color="h-custom-color"
        secondary-color="p-custom-color hover:text-gray-500"

        copyright-color="p-custom-color"

        :footer-cols="[
          {heading: 'HostNow.cz', links: [ {name: 'Ochrana súkromia', link: '/podmienky-ochrany-sukromia'}, {name: 'Všeobecné obchodné podmienky', link: '/vseobecne-obchodne-podmienky'}, {name: 'Na stiahnutie', link: '/na-stiahnutie'}]},
          {heading: 'Kontakt', links: [ {name: 'info@hostnow.cz', link: 'mailto:info@hostnow.cz'}, {name: 'Discord', link: 'https://discord.gg/m6pHpEt'}, {name: 'Instagram', link: 'https://instagram.com/hostnow'}]},
          {heading: 'Partneri', links: [ {name: 'TaskMate.eu', link: 'https://taskmate.eu'}, {name: 'Hyperbola.sk', link: 'https://hyperbola.sk'}]},
        ]"

        logo-src="text-logo.png"

        :copyright="'HostNow.cz ©' + new Date().getFullYear()"

    />
  </div>
</template>

<script>
import '../assets/index.css';
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";
import Child from "@/components/Child";

export default {
  name: "defaullt",

  components: {
    Navbar,
    Footer,
    Child
  }
}
</script>

<style scoped>

</style>