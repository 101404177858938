import Vue from 'vue';
import App from './App.vue';
import './assets/index.css';
import VueScrollMagic from 'vue-scrollmagic';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faBan,
  faBars, faCheck,
  faClock,
  faDatabase, faEthernet, faGlobe,
  faKey,
  faLock,
  faNewspaper, faPlus, faSave,
  faShieldAlt, faSortDown,
  faStar
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import router from './router'
import './components'

const icons = [faBars, faStar, faNewspaper, faDatabase, faShieldAlt, faClock, faBan, faSave, faGlobe, faEthernet, faLock,
faKey, faPlus, faCheck, faSortDown];

icons.forEach(icon => library.add(icon));

Vue.use(VueScrollMagic);

Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.config.productionTip = false;

new Vue({
  router,
  ...App
});