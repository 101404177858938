<template>
  <div id="section-right" class="pt-6 lg:pt-28 container mx-auto">
    <div class="grid grid-cols-2">
      <div id="svg" class="grid justify-items-center flex items-center col-span-2 lg:col-span-1">
        <img :src="'/' + svgSource" :alt="svgSource" draggable="false" class="svg-image">
      </div>
      <div class="col-span-2 lg:col-span-1 m-5 lg:m-0">
        <h1 class="custom-heading h-custom-color"> {{ heading }} </h1>
        <p class="custom-paragraph p-custom-color max-w-2xl"> {{ text }} </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionTextRight",
  props: {
    svgSource: String,
    heading: String,
    text: String
  }
}
</script>

<style scoped>
  .svg-image {
    @apply h-60 lg:h-72;
  }
</style>