<template>
  <div>
    <navbarmodern :nav-items="[
      {name: 'Domov', link: '/'},
      {name: 'Status', link: 'https://status.hostnow.cz/'},
      {name: 'Wiki', link: 'https://wiki.hostnow.cz/'},
      {name: 'Blog', link: 'https://blog.hostnow.cz/'},
      {name: 'Discord', link: 'https://discord.gg/kEEzRq5THg'},
      {name: 'Rozbehni svoj projekt u nás!', link: '/stan-sa-partnerom'},
      ]"/>
    <Child/>
    <Footer

        bg-color="#000D20"

        title-color="text-white"
        secondary-color="text-gray-400 hover:text-gray-600"

        copyright-color="text-gray-400"

        :footer-cols="[
          {heading: 'HostNow.cz', links: [ {name: 'Ochrana súkromia', link: '/podmienky-ochrany-sukromia'}, {name: 'Všeobecné obchodné podmienky', link: '/vseobecne-obchodne-podmienky'}, {name: 'Na stiahnutie', link: '/na-stiahnutie'}]},
          {heading: 'Kontakt', links: [ {name: 'info@hostnow.cz', link: 'mailto:info@hostnow.cz'}, {name: 'Discord', link: 'https://discord.gg/m6pHpEt'}, {name: 'Instagram', link: 'https://instagram.com/hostnow'}]},
          {heading: 'Partneri', links: [ {name: 'TaskMate.eu', link: 'https://taskmate.eu'}, {name: 'Hyperbola.sk', link: 'https://hyperbola.sk'}]},
        ]"

        logo-src="light.png"

        :copyright="'HostNow.cz ©' + new Date().getFullYear()"

    />
  </div>
</template>

<script>
import navbarmodern from '@/components/NavbarModern'

export default {
  name: "modern",
  components: {
    navbarmodern,
  }
}
</script>

<style scoped>

</style>