import Vue from 'vue';
import Child from "@/components/Child";
import SectionTextLeft from "@/components/SectionTextLeft";
import SectionTextMiddle from "@/components/SectionTextMiddle";
import SectionTextMiddleButtons from "@/components/SectionTextMiddleButtons";
import SectionTextRight from "@/components/SectionTextRight";
import Navbar from "@/components/Navbar";
import NavbarModern from "@/components/NavbarModern";
import Footer from "@/components/Footer";
import Header from "@/components/Header";
import True from "@/components/true";
import Point from "@/components/point";
import required from "@/components/required";
import question from "@/components/question";

[
    Child,
    Navbar,
    Footer,
    Header,
    SectionTextLeft,
    SectionTextMiddle,
    SectionTextMiddleButtons,
    SectionTextRight,
    NavbarModern,
    True,
    Point,
    required,
    question
].forEach(Component => {
    Vue.component(Component.name, Component)
})