<template>
  <div class="wrapper">
    <button class="question flex align-center justify-between" v-on:click="show = !show">
      <span>{{ question }}</span> <font-awesome-icon :class="{'rotate' : show}" icon="sort-down"/>
    </button>
    <div :class="{'answer' : true, 'hidden' : !show}">{{ answer }}</div>
  </div>
</template>

<script>
export default {
  name: "question",

  props: {
    question: String,
    answer: String
  },

  data: () => {
    return ({
      show: false,
    });
  }
}
</script>

<style scoped>
.question {
  @apply py-2 px-6 rounded-md text-white mb-2 shadow-xl;
  font-family: 'Space Mono', monospace;
  background-color: #0c2344;
  width: 100%;
}

.question svg {
  transition: all .3s;
}

.rotate {
  transform: rotateX(180deg) translateY(-7px);
}

.answer {
  @apply py-4 px-6 rounded-md text-white shadow-xl mb-6 text-left;
  background-color: #0c2344;
  transition: all 3s;
  font-family: 'Karla', sans-serif;
  animation: open 1s;
}

.hidden {
  height: 0;
}

.wrapper {
  width: 100%;
}

@keyframes open {
  from { max-height: 0; opacity: 0;}
  to { max-height: 100000px; opacity: 1; }
}

@keyframes close {
  from { max-height: 100000px; opacity: 1; }
  to { max-height: 0; opacity: 0; }
}

@-webkit-keyframes close {
  from { max-height: 100000px; opacity: 1; }
  to { max-height: 0; opacity: 0; }
}

@-webkit-keyframes open {
  from { height: 0; opacity: 0; }
  to { max-height: 100000px; opacity: 1; }
}
</style>