<template>
  <font-awesome-icon icon="check" class="text-green-500 mr-4"/>
</template>

<script>
export default {
  name: "true"
}
</script>

<style scoped>

</style>