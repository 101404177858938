<template>
  <div id="section-left" class="pt-20 lg:pt-60 container mx-auto">
    <div class="grid grid-cols-2">
      <div class="col-span-2 lg:col-span-1 m-5 lg:m-0">
        <h1 class="custom-heading h-custom-color"> {{ heading }} </h1>
        <p class="custom-paragraph p-custom-color max-w-2xl"> {{ text }} </p>
      </div>
      <div class="flex items-center justify-center col-span-2 lg:col-span-1 pt-36 lg:pt-0 svg">
        <img :src="'/' + svgSource1" id="under" :alt="svgSource1" style="filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.07));" draggable="false" class="svg-image overlapped-image under">
        <img :src="'/' + svgSource2" id="over" :alt="svgSource2" style="filter: drop-shadow(0px 4px 15px rgba(0, 0, 0, 0.07));" draggable="false" class="svg-image overlapped-image over">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionTextLeft",
  props: {
    svgSource1: String,
    svgSource2: String,
    heading: String,
    text: String
  },

  mounted() {
    const scene = this.$scrollmagic.scene({
      triggerElement: '#section-left',
      triggerHook: 0.5,
      duration: '200%',
    })
        .setTween('#under', {
              css: {
                y: -50,
              }
            }
        )

    const scene2 = this.$scrollmagic.scene({
      triggerElement: '#section-left',
      triggerHook: 0.5,
      duration: '50%',
    })
        .setTween('#over', {
              css: {
                y: 50,
              }
            }
        )

    this.$scrollmagic.addScene(scene);
    this.$scrollmagic.addScene(scene2);
  }
}
</script>

<style scoped>
  .svg {
    margin-left: 3cm;
  }

  .svg-image {
    height: 300px;
  }

  .overlapped-image {
    position: absolute;
    @apply h-56 md:h-64 lg:h-80;
  }

  .over {
    /*@apply ml-32 mt-16 lg:ml-52 lg:mt-20;*/
    z-index: -1;
  }


  /* Chrome version 29 and above */
  @media screen and (-webkit-min-device-pixel-ratio:0)
  and (min-resolution:.001dpcm) {
    .under {
      margin-left: -3cm;
    }
  }

  @supports (-moz-appearance:none) {
    .under { margin-left: -6cm; }
  }

  .under {
    /*@apply -ml-36;*/
    z-index: -1;
  }

  #under {
    background-position: top;
  }
</style>