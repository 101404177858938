<template>
  <div> <!--name="page" mode="out-in"-->
    <slot>
      <router-view/>
    </slot>
  </div>
</template>

<script>
export default {
  name: 'Child'
}
</script>
