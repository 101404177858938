<template>
  <span class="mr-4">-</span>
</template>

<script>
export default {
  name: "point"
}
</script>

<style scoped>

</style>